'use strict';

angular.module('angularPikaday', [])
    .directive('pikaday', function () {
        return {
            restrict: 'A',
            require: ['ngModel', '^form'],
            scope: {
                pikaday: '='
            },
            link: function (scope, elem, attrs, ctrls) {

                var ngModel = ctrls[0];
                var formCtrl = ctrls[1];
                var format = attrs.format || 'ddd MMM D YYYY';

                var picker = new Pikaday({
                    field: elem[0],
                    trigger: document.getElementById(attrs.triggerId),
                    bound: attrs.bound !== 'false',
                    position: attrs.position || '',
                    format: attrs.format || 'ddd MMM D YYYY', // Requires Moment.js for custom formatting
                    defaultDate: new Date(attrs.defaultDate),
                    setDefaultDate: attrs.setDefaultDate === 'true',
                    firstDay: attrs.firstDay ? parseInt(attrs.firstDay) : 0,
                    minDate: moment(attrs.minDate, format).toDate(),
                    maxDate: moment(attrs.maxDate, format).toDate(),
                    yearRange: attrs.yearRange ? JSON.parse(attrs.yearRange) : 10, // Accepts int (10) or 2 elem array ([1992, 1998]) as strings
                    isRTL: attrs.isRTL === 'true',
                    i18n: {
                        previousMonth: 'Previous Month',
                        nextMonth: 'Next Month',
                        months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                        weekdays: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
                        weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
                    },
                    yearSuffix: attrs.yearSuffix || '',
                    showMonthAfterYear: attrs.showMonthAfterYear === 'true'
                });

                attrs.$observe('minDate', function (value) {
                    var minDate = moment(value, attrs.format);

                    picker.setMinDate(minDate.toDate());
                });

                var firstFormatting = true;

                ngModel.$formatters.unshift(function (value) {
                    if (!ngModel.$modelValue)
                        return null;

                    var date = moment(ngModel.$modelValue);
                    if (date.isValid()) {
                        picker.setDate(date.toISOString());

                        // stop form from being marked dirty when date picker directive sets the value the first time.
                        if (firstFormatting) {
                            ngModel.$setPristine();
                            if (formCtrl)
                                formCtrl.$setPristine();
                            firstFormatting = false;
                        }

                        return date.format(attrs.format);
                    }

                    return value;
                });

                ngModel.$parsers.unshift(function (value) {
                    var date = moment(value, attrs.format);

                    return date.format('YYYY-MM-DD');
                });

                scope.pikaday = picker;
            }
        };
    });